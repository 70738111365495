import React from 'react';
import './App.css';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {BrowserRouter as Router, Link as RouterLink, Redirect, Route, Switch} from "react-router-dom";
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import logo from "./images/logos/logomark.png";
import logoHorizontal from "./images/logos/logo-horizontal-small.png";
import Dashboard from "./features/cost-of-delay/Dashboard";
import Introduction from "./features/cost-of-delay/Introduction";
import WorkingAcrossHorizons from "./features/cost-of-delay/WorkingAcrossHorizons";
import ValueModel from "./features/cost-of-delay/ValueModel";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#5f8eb3',
      main: '#2e6183',
      dark: '#003756',
      contrastText: '#fff',
    },
    secondary: {
      light: '#94deff',
      main: '#60acd3',
      dark: '#267da2',
      contrastText: '#404041',
    },
  },
});

const drawerWidth = 400;

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
    marginBottom: 0,
  },
  titleLink: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none"
    },
  },
  navLink: {
    ...theme.typography.button,
    color: "white",
    textDecoration: "none",
    marginRight: "1em"
  },
  bar: {
    zIndex: theme.zIndex.drawer + 1,
    marginBottom: "2em",
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  logo: {
    width: "20px",
    marginRight: "10px",
    verticalAlign: "middle",
  },
  logoHorizontal: {
    verticalAlign: "middle",
    marginLeft: "1em"
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginBottom: '3em',
  },
  content: {
    flex: "1 0 auto",
  },
  footer: {
    borderTop: "1px solid #ccc",
    marginTop: "1em",
    padding: "1em",
    flexShrink: 0,
    textAlign: "center",
    background: "#F5F5F6",
    zIndex: theme.zIndex.drawer + 1,
  }
}));

function Links() {
  const classes = useStyles();
  return (
    <>
      <Toolbar/>
      <div className={classes.drawerContainer}>
        <List>
          <ListItem button component={RouterLink} to="/articles/cost-of-delay-introduction">
            <Typography>
              Introduction
            </Typography>
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListItem button component={RouterLink} to="/cost-of-delay">
            <Typography>
              Build a Model
            </Typography>
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListItem button component={RouterLink} to="/articles/how-to-build-a-value-model">
            <Typography>
              How to build a value model
            </Typography>
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListItem button component={RouterLink} to="/articles/is-your-org-structure-wrong">
            <Typography>
              Insight: Is your org structure wrong?
            </Typography>
          </ListItem>
        </List>
      </div>
    </>
  );
}

function App() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className={classes.root}>
          <AppBar position="static" className={classes.bar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <Menu/>
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                <Link component={RouterLink} to="/" className={classes.titleLink}>
                  <img src={logo} alt={"Upslope Logo"} className={classes.logo}/>
                  Cost of Delay
                </Link>
              </Typography>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="site navigation">
            <Hidden xlUp implementation="css">
              <Drawer
                className={classes.drawer}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <Links/>
              </Drawer>
            </Hidden>
            <Hidden lgDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                <Links/>
              </Drawer>
            </Hidden>
          </nav>
          <div className={classes.content}>
            <Switch>
              <Route path="/" exact>
                <Redirect to={"/articles/cost-of-delay-introduction"}/>
              </Route>
              <Route path="/articles/cost-of-delay-introduction" exact component={Introduction}/>
              <Route path="/articles/is-your-org-structure-wrong" exact component={WorkingAcrossHorizons}/>
              <Route path="/articles/how-to-build-a-value-model" exact component={ValueModel}/>
              <Route path="/cost-of-delay/:modelId" exact component={Dashboard}/>
              <Route path="/cost-of-delay" exact component={Dashboard}/>
            </Switch>
          </div>
          <div className={classes.footer}>
            <Typography variant="subtitle1">
              Powered By
              <a href={`https://upslope.io?referrer=${encodeURIComponent(document.location.href)}`} target={"_blank"} rel={"noopener noreferrer"}>
                <img src={logoHorizontal} alt={"Upslope Logo"} className={classes.logoHorizontal}/>
              </a>
            </Typography>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
