import {createSelector, createSlice} from '@reduxjs/toolkit';
import moment from "moment";

const MAX_IDEAS_FOR_PERMUTATION = 9;
const initialStartDate = moment().add(1, "week").startOf("isoWeek");

export const costOfDelaySlice = createSlice({
  name: 'counter',
  initialState: {
    startDate: initialStartDate.format("YYYY-MM-DD"),
    ideas: [],
  },
  reducers: {
    replaceData: (state, action) => {
      state.ideas = action.payload.costOfDelay.ideas;
      state.startDate = action.payload.costOfDelay.startDate;
    },
    addIdea: (state, action) => {
      if (action.payload.externality === undefined) delete action.payload.externality;
      state.ideas = [...state.ideas, action.payload];
    },
    removeIdea: (state, action) => {
      state.ideas = state.ideas.filter(idea => idea.id !== action.payload.id);
    },
    updateIdea: (state, action) => {
      const index = state.ideas.findIndex(idea => idea.id === action.payload.id);
      state.ideas[index] = action.payload;
    },
  },
});

export const {replaceData, addIdea, removeIdea, updateIdea} = costOfDelaySlice.actions;

const selectIdeas = state => state.costOfDelay.ideas;

export const selectIdeaCount = state => state.costOfDelay.ideas.length;

export const selectStartDate = state => moment(state.costOfDelay.startDate);

/**
 * A memoized selector that returns the total number of weeks in the complete model
 // which is the sum of all of the delays
 */
export const selectModelLength = createSelector(
  [selectIdeas],
  ideas => ideas.reduce((sum, idea) => sum + idea.weeks, 0)
);

/**
 * A memoized selector that returns ideas with all externality dates
 * converted into week numbers relative to the startDate.
 * @param ideas - an unordered array of raw ideas from the store
 * @param startDate - the startDate from the store
 * @returns an array of ideas with new externality properties that represent weeks
 */
const selectIdeasWithRelativeExternalities = createSelector(
  [selectIdeas, selectStartDate],
  (ideas, startDate) => getIdeasWithRelativeExternalities(ideas, startDate)
);

/**
 * A memoized selector that returns all of costs for all of the potential start weeks in the model
 * @param ideas - an unordered array of ideas with relative-ized externalities
 * @param modelLength - the total number of weeks in the model
 * @returns an object that maps idea IDs => arrays of arrays representing the cost at every week, for every given start week
 */
export const selectIdeaScenarios = createSelector(
  [selectIdeasWithRelativeExternalities, selectModelLength],
  (ideas, modelLength) => allPossibleScenarios(ideas, modelLength)
);

/**
 * A memoized selector that returns ideas in their proper order, along with the cost of the total model
 * @param ideas - an unordered array of ideas with relative-ized externalities
 * @param scenarios - the scenario of costs per week for every idea, for every possible start week
 * @returns an object like {ideas: [...], cost: X} where ideas is in the best possible order and cost is the true cost
 */
export const selectIdeasInOrder = createSelector(
  [selectIdeasWithRelativeExternalities, selectIdeaScenarios],
  (ideas, scenarios) => getIdeasInOrder(ideas, scenarios)
);

/**
 * A memoized selector that returns ideas with summary data
 * @param ideasInOrder - an object with an ordered array of ideas with relative-ized externalities
 * @param scenarios - the scenario of costs per week for every idea, for every possible start week
 * @returns an object like {summarizedIdeas: [...], totalCost: X} where ideas is an array of summarized ideas in the best possible order and cost is the true cost of the entire model
 */
export const selectSummarizedIdeas = createSelector(
  [selectIdeasInOrder, selectIdeaScenarios],
  ({ideas, orderStrategy}, scenarios) => ({
    ...summarizeIdeas(ideas, scenarios),
    orderStrategy
  })
);

/**
 * if there are any externalities, and less than 9 ideas, uses permutation to determine the order
 * otherwise uses cd3 to determine the order
 */
const getIdeasInOrder = (ideas, scenarios) => {
    if (ideas.some(idea => idea.externality && ideas.length < MAX_IDEAS_FOR_PERMUTATION)) {
      return {
        ideas: minOverEveryPermutation(ideas, scenarios),
        orderStrategy: "permutation"
      };
    } else {
      let orderedByCd3 = ideas.slice().sort((a, b) => b.cost / b.weeks - a.cost / a.weeks);
      return {
        ideas: orderedByCd3,
        orderStrategy: "cd3",
      };
    }
  }
;

/**
 * decorates ideas with new properties representing calculated values for total cost, cd3 etc...
 */
const summarizeIdeas = (ideas, scenarios) => {
  return ideas.reduce((result, idea) => {
    const summarizedIdea = {
      ...idea,
      cd3: idea.cost / idea.weeks,
      startWeek: result.previousDelay,
      cumulativeDelay: idea.weeks + result.previousDelay,
      cumulativeCost: scenarios[idea.id][result.previousDelay].reduce((sum, cell) => sum + cell.value, 0),
    };

    result.previousDelay += idea.weeks;
    result.summarizedIdeas.push(summarizedIdea);
    result.totalCost += summarizedIdea.cumulativeCost;
    return result;
  }, {previousDelay: 0, totalCost: 0, summarizedIdeas: []});
};

/**
 * when a user fills in a form, the externalities are fixed dates
 * doing date math for every idea, for every week in every possible start-week scenario is expensive
 * so turn those dates into week numbers one-time here for faster calculations later
 */
const getIdeasWithRelativeExternalities = (ideas, startDate) => {
  return ideas.map(idea => {
    const relativizedIdea = {
      ...idea,
      cd3: idea.cost / idea.weeks,
    };

    let externality = idea.externality;
    if (externality) {
      let summarizedExternality = {...idea.externality};
      relativizedIdea.externality = summarizedExternality;

      if (externality.hasOwnProperty("contractStartDate")) {
        const date = moment(externality.contractStartDate).startOf("isoWeek");
        summarizedExternality.contractStartWeek = date.diff(startDate, "weeks");
      } else if (externality.hasOwnProperty("oneTimeCostDate")) {
        const date = moment(externality.oneTimeCostDate).startOf("isoWeek");
        summarizedExternality.oneTimeCostWeek = date.diff(startDate, "weeks");
      } else if (externality.hasOwnProperty("recurringCostStartDate")) {
        const date = moment(externality.recurringCostStartDate).startOf("isoWeek");
        summarizedExternality.recurringCostStartWeek = date.diff(startDate, "weeks");
      } else if (externality.hasOwnProperty("perpetualLossStartDate")) {
        const date = moment(externality.perpetualLossStartDate).startOf("isoWeek");
        summarizedExternality.perpetualLossStartWeek = date.diff(startDate, "weeks");
      }
    }

    return relativizedIdea;
  });
};

/**
 * determines the actual cost for every idea, for every week in every possible start-week scenario
 * returns an object that maps idea.id => startWeek => array of costs given that start week
 */
const allPossibleScenarios = (ideasWithRelativeWeeks, modelLength) => {
  return ideasWithRelativeWeeks.reduce((result, idea) => {
    return {
      ...result,
      [idea.id]: [
        ...(new Array(modelLength - idea.weeks + 1).fill(0).map((_, row) => {
          return new Array(modelLength).fill(0).map((_, column) => {
              const max = row + idea.weeks - 1;
              let type = "currency";
              let cost = 0;
              let waste = false;

              let externality = idea.externality;
              if (externality) {
                if (externality.hasOwnProperty("contractStartWeek")) {
                  if (column < externality.contractStartWeek) {
                    cost = 0;
                  } else if (column > max) {
                    if (externality.contractStartWeek > max) {
                      cost = 0;
                    } else {
                      if ((max - externality.contractStartWeek + 1) % externality.contractTermWeeks === 0) {
                        cost = 0;
                      } else if ((column - externality.contractStartWeek) % externality.contractTermWeeks === 0) {
                        cost = 0;
                      } else if (Math.floor((column - externality.contractStartWeek) / externality.contractTermWeeks) === Math.floor((max - externality.contractStartWeek + 1) / externality.contractTermWeeks)) {
                        cost = idea.cost;
                        waste = true;
                      }
                    }
                  } else {
                    cost = idea.cost;
                  }
                } else if (externality.hasOwnProperty("oneTimeCostWeek")) {
                  if (max < externality.oneTimeCostWeek) {
                    cost = 0;
                  } else {
                    cost = column === externality.oneTimeCostWeek ? idea.cost : 0;
                  }
                } else if (externality.hasOwnProperty("recurringCostStartWeek")) {
                  if (max < externality.recurringCostStartWeek) {
                    cost = 0;
                  } else if (column >= externality.recurringCostStartWeek && column <= max) {
                    cost = idea.cost;
                  } else {
                    cost = 0;
                  }
                } else if (externality.hasOwnProperty("perpetualLossStartWeek")) {
                  if (max < externality.perpetualLossStartWeek) {
                    cost = 0;
                  } else if (column >= externality.perpetualLossStartWeek) {
                    cost = idea.cost;
                    waste = true;
                  } else {
                    cost = 0;
                  }
                }
              } else if (column <= max) {
                cost = idea.cost;
              }

              let state;
              if (column >= row && column <= max) {
                state = "building";
              } else if (column < row) {
                state = "waiting";
              } else if (column > row) {
                state = "delivered";
              }
              return {value: cost, state, type, waste};
            }
          );
        }))
      ]
    };
  }, {});
};

/**
 * determines the actual cost for a given sequence of ideas
 * returns the actual cost, calculated from the scenarios
 */
const costOf = (ideas, scenarios) => {
  let week = 0;
  let permutationCost = 0;
  for (const idea of ideas) {
    const cost = scenarios[idea.id][week];
    permutationCost += cost;
    week += idea.weeks;
  }
  return {
    ideas,
    cost: permutationCost
  };
};

/**
 * calls the generator function to permute every possible combination of ideas (with relative externalities)
 * calculates the cost of each scenario
 * returns the array of ordered ideas with the lowest cost
 */
const minOverEveryPermutation = (ideasWithRelativeExternalities, scenarios) => {
  let minCost = undefined;
  let minPermutation = undefined;
  for (const permutation of permute(ideasWithRelativeExternalities)) {
    let {cost: permutationCost} = costOf(permutation, scenarios);
    if (typeof minCost === 'undefined' || permutationCost < minCost) {
      minCost = permutationCost;
      // we'd push to an array of ideal solutions - smart about it (kill ideas with higher values)
      // also capture an array of all costs and produce a histogram
      minPermutation = permutation;
    }
  }
  return minPermutation;
};

/**
 * a generator function that lazily permutes an array using a heap
 * based on https://stackoverflow.com/a/37580979
 */
function* permute(input) {
  const permutation = input.slice();
  let length = permutation.length,
    c = Array(length).fill(0),
    i = 1, k, p;

  yield permutation;
  while (i < length) {
    if (c[i] < i) {
      k = i % 2 && c[i];
      p = permutation[i];
      permutation[i] = permutation[k];
      permutation[k] = p;
      ++c[i];
      i = 1;
      yield permutation.slice();
    } else {
      c[i] = 0;
      ++i;
    }
  }
}

export default costOfDelaySlice.reducer;
