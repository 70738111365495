import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

firebase.initializeApp({
  projectId: "upslope-cost-of-delay",
  apiKey: "AIzaSyBJJhiGrVFtiSFSoSj0O8qU2f67XbjeAnE\n",
  authDomain: "upslope-cost-of-delay.firebaseapp.com",
});

export const auth = firebase.auth;
export const db = firebase.firestore();

// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost") {
  db.settings({
    host: "localhost:4000",
    ssl: false
  });
}
