import React from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";

export const moneyField = {
  InputLabelProps: {shrink: true},
  required: true,
  type: "number",
  inputProps: {min: 0},
  variant: "filled",
  InputProps: {
    startAdornment: <InputAdornment position="start">$</InputAdornment>
  }
};

export const moneyPerWeekField = {
  InputLabelProps: {shrink: true},
  required: true,
  type: "number",
  inputProps: {min: 0},
  variant: "filled",
  InputProps: {
    startAdornment: <InputAdornment position="start">$</InputAdornment>,
    endAdornment: <InputAdornment position="start">per week</InputAdornment>
  }
};

export const weeksField = {
  InputLabelProps: {shrink: true},
  required: true,
  type: "number",
  inputProps: {min: 1},
  variant: "filled",
  InputProps: {
    endAdornment: <InputAdornment position="start">weeks</InputAdornment>
  }
};

export const dateField = {
  InputLabelProps: {shrink: true},
  required: true,
  type: "date",
  inputProps: {min: new Date()},
  variant: "filled"
};
