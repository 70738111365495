import React, {useReducer} from "react";
import {Container} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Title from "../../shared/Title";
import teal from "@material-ui/core/colors/teal";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TextField from "@material-ui/core/TextField";
import {getCurrencyFormatter, getNumberFormatter, getPercentFormatter} from "../../formatter";
import {irr} from "financial";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
  section: {
    marginTop: "2em",
    paddingBottom: "5em",
  },
  p: {
    marginBottom: "1em"
  },
  assumptionField: {
    textAlign: "right",
    width: "6em",
    padding: "2px",
    fontSize: "14px",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  callToAction: {
    textAlign: "center",
    background: teal[100],
    padding: "2em"
  },
  npv: {
    background: "#efefef",
    padding: "2em",
    marginBottom: "2em",
  }
}));

function numerify(string, divideBy = 1) {
  return parseFloat(string.replace(/[^\d.]/g, "")) / divideBy;
}

function model(months, variables) {
  let prev = {newMrr: 0, cumlMrr: 0, revenueChurn: 0, acquisitionCost: 0, cogs: 0, cashFlow: 0};

  let rows = [
    {header: "New MRR", cells: []},
    {header: "Cuml MRR", cells: []},
    {header: "Revenue Churn", cells: []},
    {header: "Acquisition Cost", cells: []},
    {header: "COGS", cells: []},
    {header: "Cash Flow", cells: []},
  ];

  let rampPeriod = numerify(variables["Ramp period (months)"]);
  let subscriptionPricePerMonth = numerify(variables["Subscription price (per month)"]);
  let peakUnitsMonthly = numerify(variables["Peak Units (monthly)"]);
  let churnRateAnnual = numerify(variables["Churn Rate (annual)"], 100);
  let acquisitionCostPerUnit = numerify(variables["Acquisition Cost (per unit)"]);
  let cogsPerMonth = numerify(variables["COGS (per month)"]);

  for (let i = 0; i < months; i++) {
    let month = i + 1;

    const newMrr = month < rampPeriod ? (subscriptionPricePerMonth * (month / rampPeriod * peakUnitsMonthly)) : subscriptionPricePerMonth * peakUnitsMonthly;
    const cumlMrr = newMrr + prev.cumlMrr - prev.revenueChurn;
    const revenueChurn = cumlMrr * churnRateAnnual / 12;
    const acquisitionCost = newMrr / subscriptionPricePerMonth * acquisitionCostPerUnit;
    const cogs = cumlMrr / subscriptionPricePerMonth * cogsPerMonth;
    const cashFlow = cumlMrr - revenueChurn - acquisitionCost - cogs;

    prev = {newMrr, cumlMrr, revenueChurn, acquisitionCost, cogs, cashFlow};

    Object.values(prev).forEach((value, index) => rows[index].cells.push(value));
  }

  rows.forEach(row => row.cells.push(row.cells.reduce((a, b) => a + b, 0)));

  const rowsByYear = rows.reduce((result, row) => {
    result[row.header] = {};
    const values = row.cells.slice(0, row.cells.length - 1);
    for (const [i, val] of values.entries()) {
      const year = Math.floor(i / 12);
      result[row.header][year] = result[row.header][year] || [];
      result[row.header][year].push(val);
    }
    return result;
  }, {});

  const summary = {
    "Year": [],
    "Cash Flow": [],
    "Discounted Cash Flow": []
  };

  [...new Array((months / 12) + 1)].forEach((_, year) => {
    const cashFlow = year === 0 ? numerify(variables["Cost to build (one time)"]) * -1 : rowsByYear["Cash Flow"][year - 1].reduce((a, b) => a + b, 0);
    const discountedCashFlow = year === 0 ? cashFlow : cashFlow / Math.pow((1 + numerify(variables["Discount rate / WACC"], 100)), year);

    summary["Year"].push(year);
    summary["Cash Flow"].push(cashFlow);
    summary["Discounted Cash Flow"].push(discountedCashFlow);
  });

  return {
    rows,
    summary,
    npv: summary["Discounted Cash Flow"].reduce((a, b) => a + b, 0),
    irr: irr(summary["Cash Flow"])
  };
}

const initialState = {
  "Subscription price (per month)": "24.0",
  "Cost to build (one time)": "$500,000",
  "Acquisition Cost (per unit)": "$50",
  "COGS (per month)": "$10",
  "Ramp period (months)": "24",
  "Peak Units (monthly)": "100.00",
  "Churn Rate (annual)": "15%",
  "Discount rate / WACC": "12%",
};

const variableSettings = {
  "Subscription price (per month)": {
    sensitivity: 0.9,
    formatter: getCurrencyFormatter,
    numerify,
  },
  "Cost to build (one time)": {
    formatter: getCurrencyFormatter,
    numerify,
  },
  "Acquisition Cost (per unit)": {
    sensitivity: 1.1,
    formatter: getCurrencyFormatter,
    numerify,
  },
  "COGS (per month)": {
    sensitivity: 1.1,
    formatter: getCurrencyFormatter,
    numerify,
  },
  "Ramp period (months)": {
    sensitivity: 1.1,
    formatter: getNumberFormatter,
    numerify,
  },
  "Peak Units (monthly)": {
    sensitivity: 0.9,
    formatter: getNumberFormatter,
    numerify,
  },
  "Churn Rate (annual)": {
    sensitivity: 1.1,
    formatter: getPercentFormatter,
    numerify: val => numerify(val, 100),
  },
  "Discount rate / WACC": {
    formatter: getPercentFormatter,
    numerify: val => numerify(val, 100),
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'updateAssumption':
      return {...state, [action.assumption]: action.value};
    default:
      throw new Error();
  }
}

function ValueModel() {
  const classes = useStyles();

  const [assumptions, dispatch] = useReducer(reducer, initialState);

  const months = 60;
  const {rows, summary, irr: baseIrr, npv: baseNpv} = model(months, assumptions);

  // const variationMap = Object.entries(assumptions).reduce((result, [assumption, value]) => {
  //   if (variableSettings[assumption].sensitivity) {
  //     const alteredAssumptions = {
  //       ...assumptions,
  //       [assumption]: variableSettings[assumption].formatter(2, 2).format(
  //         variableSettings[assumption].numerify(value) * variableSettings[assumption].sensitivity
  //       )
  //     };
  //     const alteredModel = model(months, alteredAssumptions);
  //     const npvChange = (baseNpv - alteredModel.npv) / baseNpv;
  //     result[assumption] = (npvChange * 100 * -1).toFixed(2);
  //   }
  //   return result;
  // }, {});

  // const variations = Object.entries(variationMap).sort(([_, a], [__, b]) => a - b);

  return (
    <Container maxWidth={"md"}>
      <Title text={"How to build a value model"}/>
      <Typography variant={"h3"} component={"h1"}>
        How to build a value model
      </Typography>
      <section className={classes.section}>
        <Box mb={2}>
          <Typography>
            The only thing we can be certain of when building a financial value model is that it will be wrong. It’s a
            comforting reality actually because it reminds us not to spend too much time making it “perfect”. Remember,
            the purpose of a model isn’t to predict the future. The purpose of a model is to help decision-makers
            prioritize the work of the organization.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            A more useful expectation is the creation of a “sound” model. Sound models include reasonable assumptions,
            use correct math, and avoid calculation and formula errors. For the purposes of this article, we’ll focus
            mainly on our variable assumptions, looking at a few of the pitfalls to be aware of when either building or
            evaluating models.
          </Typography>
        </Box>

        <Box mb={4} mt={4}>
          <Typography variant={"h4"}>
            Pitfall #1: Overly optimistic assumptions
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            We all think that our ideas are great, and we want them to succeed. But the reality is that most ideas are
            bad, and even the good ones require several rounds of iteration before they “hit.” One of the biggest
            pitfalls to avoid when modeling is to overstate the benefit.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography component={"div"}>
            One way to combat this is to make very granular assumptions. For example:
            <ul>
              <li>What price point do you assume and why? Are you a new entrant? Is there a market leader? Is the market
                distributed?
              </li>
              <li>What’s your estimate of the size of the market?</li>
              <li>What level of market penetration do you expect?</li>
              <li>What do you expect the ramp rate to be?</li>
            </ul>
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            As you’ll see below, you might be able to independently verify some of these variables through testing. And
            if someone reads the model later, and thinks that it’s off, they can give you very specific feedback about
            which number they think is inaccurate.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            “the most common mistake in strategy is believing your competitor/opponent is a speed bag and doesn’t
            observe, calculate, and hit back.”
            - <a href={"https://www.profgalloway.com/microsoft-tiktok-nfw"} target={"_blank"} rel={"noopener noreferrer"}>Scott
            Galloway</a>
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            Beware that people tend to underestimate competitive reactions. If you lower prices in an attempt to gain
            market share as the low entrant, does your model assume that everyone else’s prices are going to stay the
            same? This can lead to overly optimistic models.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            “When preparing to travel, lay out all your clothes and all your money. Then take half the clothes and twice
            the money” – Susan Heller
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            Optimistic assumptions aren’t just about new revenue - you also have to take costs into account. Use the
            same concept of “Optimistic / Realistic / Pessimistic” to create ranges.
          </Typography>
        </Box>

        <Box mb={4} mt={4}>
          <Typography variant={"h4"}>
            Pitfall #2: Unvalidated assumptions
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            If you have no idea what someone will pay for your product, don’t guess. Run a small test. Whether it’s an
            Ad campaign that links to a landing page, a round of user interviews, or a series of letters of intent from
            potential customers, get feedback from real people early and often.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            These techniques are described in great detail in books like The Startup Owner’s Manual by <a
            href={"https://steveblank.com/startup-owners-manual-1in/"} target={"_blank"} rel={"noopener noreferrer"}>Steve
            Blank</a>, and <a href={"https://svpg.com/inspired-how-to-create-products-customers-love/"}
                              target={"_blank"} rel={"noopener noreferrer"}>Inspired</a> by
            Marty Cagan.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            Unfortunately, in many organizations, the people who model out business ideas are completely separate from
            the developers and UX designers who have the skills necessary to build prototypes. If you are putting
            random, unsubstantiated numbers in a spreadsheet, then challenge yourself to find partners in your
            organization to help with this customer validation early on.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            When you pair your experimental results with your competitive research, you can start to build confidence in
            your assumptions.
          </Typography>
        </Box>

        <Box mb={4} mt={4}>
          <Typography variant={"h4"}>
            Pitfall #3: Hypersensitive variables
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            Some variables are more impactful than others. You need to figure out which ones those are and then quantify
            the impact of the model output to small changes in the input.

          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            For example, your model might assume it will take 12 months to get from zero to peak monthly revenue and
            that you will be charging $100 per unit. A 10% change in the ramp rate, say from 52 weeks to 57 weeks might
            have a negligible effect on ROI. But a 10% miss in your pricing assumption, say from $100 to $90, could move
            your project below an acceptable rate of return.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            Understanding sensitive variables can help yo prioritize which experiments to run. For example, if your
            value model is highly sensitive to price, then you should validate that users are willing to pay that price
            before spending lots of money developing a product and only learning later than nobody is willing to pay for
            it.
          </Typography>
        </Box>

        <Box mb={4} mt={4}>
          <Typography variant={"h4"}>
            How to increase model confidence:
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            One of the most effective tools I’ve used is a toggle switch for the most sensitive variables (or if your
            excel skills aren’t that good, just copy and paste your model and create three versions, and summarize the
            results for your audience).
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            For example, if price is the most sensitive variable in the model, you might add “Most likely”,
            “Pessimistic”, “Optimistic”, and show how the predicted ROI changes.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            At the heart of this modeling method is the acknowledgment that you really don’t know what’s going to happen
            in the future. By building that reality into the model, you give decision-makers more tools to help them
            move forward, which ultimately increases the confidence that their prioritization strategy is well-informed,
            reasonable, and defensible (bosses have bosses too!).
          </Typography>
        </Box>

        <Box mb={4} mt={4}>
          <Typography variant={"h4"}>
            Making a decision:
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            Congratulations, you built a sound model!! Now what? Presumably, there is more work to be done than the
            organization has resources for. You can use several methods for deciding what to do first, second, third,
            etc.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            You can use the Highest Paid Person’s Opinion method. You can use corporate objectives as a guide. You can
            use straight revenue potential. Or, you can prioritize using Cost of Delay models.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            However, don’t fall into the trap of greenlighting a project just because of its relative attractiveness.
            There’s still a bigger, objective question: Should you do this project at all? In the words of a CIO we work
            with, “sometimes doing nothing can be more valuable than doing something.” She’s absolutely right.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            For now, remember to avoid common modeling pitfalls and resist the urge to build perfect models. Instead,
            focus on building models that can help your teammates make the best decisions possible.
          </Typography>
        </Box>

        <Box mb={4} mt={4}>
          <Typography variant={"h4"}>
            Example Model
          </Typography>
        </Box>

        <TableContainer>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell align={"right"}>Base</TableCell>
                <TableCell align={"right"}>10% Change</TableCell>
                <TableCell align={"right"}>NPV Change</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                Object.entries(assumptions).map(([assumption, value]) => (
                  <TableRow key={`sensitivity-${assumption}`}>
                    <TableCell>{assumption}</TableCell>
                    <TableCell align={"right"}>
                      <TextField variant={"outlined"}
                                 value={value}
                                 size={"small"}
                                 inputProps={{className: classes.assumptionField}}
                                 onChange={e => {
                                   dispatch({type: "updateAssumption", assumption, value: e.target.value});
                                 }}/>
                    </TableCell>
                    <TableCell align={"right"}>
                      {
                        baseNpv < 0 || variableSettings[assumption].sensitivity === undefined
                          ? ""
                          : variableSettings[assumption].formatter(2, 2).format(
                          variableSettings[assumption].numerify(assumptions[assumption]) * variableSettings[assumption].sensitivity
                          )
                      }
                    </TableCell>
                    <TableCell align={"right"}>
                      {
                        baseNpv < 0 || variableSettings[assumption].sensitivity === undefined
                          ? ""
                          : `${value.replace("%", "")}%`
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container className={classes.npv}>
          <Grid item xs={6}>
            <Typography variant={"h6"}>
              NPV: {getCurrencyFormatter(2, 2).format(baseNpv)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {
              baseNpv < 0 && (
                <div>
                  <Typography>
                    Oh no! The NPV is now negative.
                  </Typography>
                </div>
              )
            }

            {
              baseNpv >= 0 && (
                <>
                  <Typography variant={"h6"}>
                    IRR: {getPercentFormatter(0, 0).format(baseIrr)}
                  </Typography>
                </>
              )
            }
          </Grid>
        </Grid>

        <Box mb={2} mt={2}>
          <Typography variant={"h6"}>
            Yearly Projections
          </Typography>
        </Box>

        <TableContainer>
          <Table size={"small"}>
            <TableBody>
              {
                Object.entries(summary).map(([header, cells], row) => (
                  <TableRow key={header}>
                    <TableCell>{header}</TableCell>
                    {
                      cells.map((cell, i) => (
                        <TableCell align={"right"} key={`year-${header}-${i}`}>
                          {row === 0 ? cell : getCurrencyFormatter(1, 1).format(cell)}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Box mb={2} mt={4}>
          <Typography variant={"h6"}>
            Monthly Projections
          </Typography>
        </Box>

        <TableContainer>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell>Month</TableCell>
                {
                  [...new Array(months)].map((_, i) => (
                    <TableCell align={"right"} key={`month-${i}`}>{i + 1}</TableCell>
                  ))
                }
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rows.map((row) => (
                  <TableRow key={row.header}>
                    <TableCell className={classes.noWrap}>{row.header}</TableCell>
                    {
                      row.cells.map((cell, col) => (
                        <TableCell key={`${row}-${col}`}
                                   align={"right"}>{getCurrencyFormatter(1, 1).format(cell)}</TableCell>
                      ))
                    }
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>

      </section>
    </Container>
  );
}

export default ValueModel;
