import React from 'react';
import {useSelector} from "react-redux";
import {selectIdeaScenarios, selectModelLength, selectStartDate, selectSummarizedIdeas} from "./costOfDelaySlice";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import amber from "@material-ui/core/colors/amber";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import {formatCurrency} from "../../formatter";
import {PriorityHigh} from "@material-ui/icons";
import red from "@material-ui/core/colors/red";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  waiting: {
    background: grey[200],
  },
  building: {
    background: amber[50],
  },
  delivered: {
    background: green[100],
  },
  waste: {
    background: red[100],
  },
  weeksColumn: {
    width: "2em",
  },
  dateColumn: {
    width: "6em",
  },
  right: {
    float: "right",
  },
});

function Spreadsheet() {
  const classes = useStyles();
  const {summarizedIdeas: ideas} = useSelector(selectSummarizedIdeas);
  const scenarios = useSelector(selectIdeaScenarios);
  const startDate = useSelector(selectStartDate);
  const modelLength = useSelector(selectModelLength);

  if (!ideas.length) {
    return "";
  }

  return (
    <>
      <Typography component={"div"} className={classes.right}>
        Legend:&nbsp;
        <Chip label={"Waiting"} className={classes.waiting}/>&nbsp;
        <Chip label={"Building"} className={classes.building}/>&nbsp;
        <Chip label={"Delivered"} className={classes.delivered}/>
      </Typography>

      <Box mb={2}>
        <Typography variant="h6">
          Here's how your ideas will be delivered:
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">Week</TableCell>
              <TableCell component="th" scope="row">Date</TableCell>
              {
                ideas.map(idea => (
                  <TableCell align="right" key={`header-${idea.id}`}>{idea.name}</TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              [...new Array(modelLength)].map((_, i) => (
                <TableRow key={`row-${i}`}>
                  <TableCell className={classes.weeksColumn} component="th" scope="row">{i + 1}</TableCell>
                  <TableCell className={classes.dateColumn} component="th"
                             scope="row">{moment(startDate).add(i, 'weeks').format("MM/DD/YYYY")}</TableCell>
                  {
                    ideas.map(idea => {
                      let cell = scenarios[idea.id][idea.startWeek][i];
                      return (
                        <TableCell className={classes[cell.state]} align="right" key={`row-${i}-${idea.id}`}>
                          {cell.waste ? <PriorityHigh fontSize={"small"} color={"error"}/> : ''}
                          {formatCurrency(cell.value)}
                        </TableCell>
                      );
                    })}
                </TableRow>
              ))
            }
          </TableBody>
          {/*<TableFooter>*/}
          {/*  <TableRow>*/}
          {/*    <TableCell component="th" scope="row">Cost of Delay</TableCell>*/}
          {/*    <TableCell component="th" scope="row"/>*/}
          {/*    {*/}
          {/*      rows[rows.length - 1].slice(1).map((total, i) => (*/}
          {/*        <TableCell align="right" key={`row-total-${i}`}>*/}
          {/*          ${total.toLocaleString()}*/}
          {/*        </TableCell>*/}
          {/*      ))*/}
          {/*    }*/}
          {/*  </TableRow>*/}
          {/*</TableFooter>*/}
        </Table>
      </TableContainer>
    </>
  );
}

export default Spreadsheet;
