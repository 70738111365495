import React from "react";
import {Container, TableHead} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Chart from "react-google-charts";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {formatCurrency, formatNumber} from "../../formatter";
import makeStyles from "@material-ui/core/styles/makeStyles";
import amber from "@material-ui/core/colors/amber";
import Box from "@material-ui/core/Box";
import TableFooter from "@material-ui/core/TableFooter";
import Title from "../../shared/Title";
import Button from "@material-ui/core/Button";
import {Link as RouterLink} from "react-router-dom";
import teal from "@material-ui/core/colors/teal";

const useStyles = makeStyles(() => ({
  chart: {
    margin: "2em 0"
  },
  section: {
    marginTop: "2em",
    paddingBottom: "5em",
  },
  highlight: {
    background: amber[50]
  },
  tableContainer: {
    margin: "2em 0"
  },
  dark: {
    color: "black",
    fontWeight: "bold",
  },
  p: {
    marginBottom: "1em"
  },
  callToAction: {
    textAlign: "center",
    background: teal[100],
    padding: "2em"
  },
}));

class Idea {
  constructor({name, weeks, cost, color}) {
    this.name = name;
    this.weeks = weeks;
    this.cost = cost;
    this.color = color;
  }

  get cd3() {
    return this.cost / this.weeks;
  }
}

class IdeaSequence {
  #ideas;
  #summarizedIdeas;
  #totalCost;

  constructor(ideas) {
    this.#ideas = ideas;
  }

  get ideas() {
    return this.#summarizedIdeas = this.#summarizedIdeas || this.#ideas.reduce((result, idea) => {
      result.ideas.push({
        ...idea,
        cd3: idea.cd3,
        previousDelay: result.previousDelay,
        cumulativeDelay: result.previousDelay + idea.weeks,
        cumulativeCost: (result.previousDelay + idea.weeks) * idea.cost
      });
      result.previousDelay += idea.weeks;
      return result;
    }, {ideas: [], previousDelay: 0}).ideas;
  }

  get totalCost() {
    return this.#totalCost = this.#totalCost || this.ideas.reduce((sum, idea) => sum + idea.cumulativeCost, 0);
  }

  get totalRevenue() {
    let result = 0;
    for (let i = 0; i < this.ideas.length; i++) {
      for (let p = 0; p < this.ideas[i].weeks; p++) {
        for (let ideaWeek = 0; ideaWeek < this.ideas.length; ideaWeek++) {
          result += (ideaWeek >= i) ? 0 : this.ideas[ideaWeek].cost;
        }
      }
    }
    return result;
  }

  get graphData() {
    const result = [
      ["Week", ...this.ideas.map(idea => idea.name)]
    ];
    let week = 0;
    for (let i = 0; i < this.ideas.length; i++) {
      for (let p = 0; p < this.ideas[i].weeks; p++) {
        const weekData = [++week];
        for (let ideaWeek = 0; ideaWeek < this.ideas.length; ideaWeek++) {
          let revenue = (ideaWeek >= i) ? 0 : this.ideas[ideaWeek].cost;
          weekData.push(revenue);
        }
        result.push(weekData);
      }
    }
    result.push([++week, ...this.ideas.map(idea => idea.cost)]);
    result.push([++week, ...this.ideas.map(idea => idea.cost)]);
    return result;
  }
}

function Introduction() {
  const classes = useStyles();

  const ideas = [
    new Idea({
      // cd3 of 4
      name: 'Idea A',
      weeks: 4,
      cost: 1600,
      color: 'red',
    }),
    new Idea({
      // cd3 of 3
      name: 'Idea B',
      weeks: 9,
      cost: 2700,
      color: 'blue',
    }),
    new Idea({
      // cd3 of 5
      name: 'Idea C',
      weeks: 6,
      cost: 3500,
      color: 'green',
    }),
    new Idea({
      // cd3 of 7
      name: 'Idea D',
      weeks: 2,
      cost: 1400,
      color: 'purple',
    }),
  ];

  const totalWeeks = ideas.reduce((sum, idea) => sum + idea.weeks, 0);
  const worstSequence = new IdeaSequence(ideas.slice().sort((a, b) => a.cd3 - b.cd3));
  const bestSequence = new IdeaSequence(ideas.slice().sort((a, b) => b.cd3 - a.cd3));

  return (
    <Container maxWidth={"md"}>
      <Title text={"Introduction"}/>
      <Typography variant={"h3"} component={"h1"}>
        Cost of Delay Introduction
      </Typography>
      <section className={classes.section}>
        <Typography className={classes.p}>
          By the end of this article you should be able to prioritize product ideas in a way that maximizes monetary
          benefit.
        </Typography>
        <Box className={classes.callToAction}>
          <Typography variant={"subtitle1"}>
            Already know how to do it?
          </Typography>
          <Button component={RouterLink} to={"/cost-of-delay"} variant={"contained"} color={"primary"} size={"large"}>
            Start Building Your Model Now
          </Button>
        </Box>
      </section>
      <section className={classes.section}>
        <Typography variant={"h4"} component={"h2"}>
          Order Matters
        </Typography>
        <Typography className={classes.p}>
          Imagine that you have {ideas.length} product ideas. For each idea, you have an estimate for how long
          it will take to deliver, and an estimate of how much you'll make per week:
        </Typography>
        <TableContainer className={classes.tableContainer}>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="col">Idea</TableCell>
                <TableCell align={"right"} component="th" scope="col">Weeks to Build</TableCell>
                <TableCell align={"right"} component="th" scope="col">Revenue Per Week</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                ideas.map((idea, i) => (
                  <TableRow key={i}>
                    <TableCell>{idea.name}</TableCell>
                    <TableCell align={"right"}>{idea.weeks}</TableCell>
                    <TableCell align={"right"}>{formatCurrency(idea.cost)}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Typography className={classes.p}>
          The total time it will take to build all of these ideas is <strong>{totalWeeks} weeks</strong>.
        </Typography>
        <Typography className={classes.p}>
          But depending on how you prioritize them, you could either earn more or less during those {totalWeeks} weeks.
        </Typography>
        <Typography className={classes.p}>
          For example, compare these two orders:
        </Typography>
        <Box mt={4}>
          <Typography variant={"h6"}>
            {worstSequence.ideas.reduce((result, idea) => result === null ? [idea.name] : [...result, <>&nbsp; &rarr; &nbsp;</>, idea.name], null)}
          </Typography>
        </Box>

        <div className={classes.chart}>
          <Chart
            width={'100%'}
            height={'200px'}
            chartType="AreaChart"
            loader={<div>Loading Chart</div>}
            data={worstSequence.graphData}
            options={{
              isStacked: true,
              legend: {position: 'top'},
              hAxis: {
                title: 'Week'
              },
              vAxis: {
                title: 'Revenue',
                format: 'currency',
              },
              colors: worstSequence.ideas.map(idea => idea.color),
            }}
          />
        </div>
        <Typography className={classes.p}>
          By prioritizing in the
          order {worstSequence.ideas.reduce((result, idea) => result === null ? [idea.name] : [...result, <>&nbsp; &rarr; &nbsp;</>, idea.name], null)},
          over {totalWeeks} weeks,
          you'll make <strong>{formatCurrency(worstSequence.totalRevenue)}</strong>.
        </Typography>

        <Typography className={classes.p}>
          However, if you order things differently, you <em>could</em> make more:
        </Typography>

        <Box mt={4}>
          <Typography variant={"h6"}>
            {bestSequence.ideas.reduce((result, idea) => result === null ? [idea.name] : [...result, <>&nbsp; &rarr; &nbsp;</>, idea.name], null)}
          </Typography>
        </Box>
        <div className={classes.chart}>
          <Chart
            width={'100%'}
            height={'200px'}
            chartType="AreaChart"
            loader={<div>Loading Chart</div>}
            data={bestSequence.graphData}
            options={{
              isStacked: true,
              legend: {position: 'top'},
              hAxis: {
                title: 'Week'
              },
              vAxis: {
                title: 'Revenue',
                format: 'currency',
              },
              colors: bestSequence.ideas.map(idea => idea.color),
            }}
          />
        </div>

        <Typography className={classes.p}>
          By prioritizing in the
          order {bestSequence.ideas.reduce((result, idea) => result === null ? [idea.name] : [...result, <>&nbsp; &rarr; &nbsp;</>, idea.name], null)},
          over {totalWeeks} weeks,
          you'll make <strong>{formatCurrency(bestSequence.totalCost)}</strong>.
        </Typography>

        <Typography className={classes.p}>
          The order in which you deliver your ideas can have a material
          impact on your finances.
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant={"h4"} component={"h2"}>
          Thinking in terms of Cost vs. Value
        </Typography>

        <Typography className={classes.p}>
          In order to make the math easier, you can think in terms of the cost of waiting for an idea to be delivered.
        </Typography>

        <Typography className={classes.p}>
          Instead of saying "I'll make $4,000 per week once this is delivered" you can say "We're losing out on
          $4,000 per week by <em>not</em> having this right now."
        </Typography>

        <Typography className={classes.p}>
          This allows you to quantify the <strong>Cost of Delay</strong> - which is the grey area in the chart below:
        </Typography>

        <div className={classes.chart}>
          <Chart
            width={'100%'}
            height={'200px'}
            chartType="AreaChart"
            loader={<div>Loading Chart</div>}
            data={
              bestSequence.graphData.map((row, i) => {
                if (i === 0) {
                  return [...row, "Cost of Delay"];
                } else {
                  return [...row, ideas.reduce((sum, idea) => sum + idea.cost, 0) - row.reduce((sum, num) => sum + num, 0)];
                }
              })
            }
            options={{
              isStacked: true,
              height: 200,
              legend: {position: 'top'},
              hAxis: {
                title: 'Week'
              },
              vAxis: {
                title: 'Revenue',
                minValue: 0,
                maxValue: ideas.reduce((sum, idea) => sum + idea.cost, 0),
                format: 'currency',
              },
              colors: [...bestSequence.ideas.map(idea => idea.color), 'grey'],
            }}
          />
        </div>

        <Typography className={classes.p}>
          From now on you can change the heading "Revenue per Week" to the <strong>Cost (of not having it)</strong>:
        </Typography>

        <TableContainer className={classes.tableContainer}>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="col">Idea</TableCell>
                <TableCell align={"right"} component="th" scope="col">Weeks to Build</TableCell>
                <TableCell className={classes.highlight} align={"right"} component="th" scope="col">
                  Cost (of not having it)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                ideas.map((idea, i) => (
                  <TableRow key={i}>
                    <TableCell>{idea.name}</TableCell>
                    <TableCell align={"right"}>{idea.weeks}</TableCell>
                    <TableCell align={"right"}>{formatCurrency(idea.cost)}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </section>

      <section className={classes.section}>
        <Typography variant={"h4"} component={"h2"}>
          Delays Are Cumulative
        </Typography>
        <Typography className={classes.p}>
          The reason that order matters is because delays are cumulative:
        </Typography>
        <TableContainer className={classes.tableContainer}>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="col">Idea</TableCell>
                <TableCell align={"right"} className={classes.highlight} component="th" scope="col">Duration (to
                  build)</TableCell>
                <TableCell align={"right"} component="th" scope="col">Cost (of not having it)</TableCell>
                <TableCell align={"right"} className={classes.highlight} component="th" scope="col">
                  Cumulative Delay
                </TableCell>
                <TableCell align={"right"} className={classes.highlight} component="th" scope="col">
                  Cumulative Cost (of not having it)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                worstSequence.ideas.map((idea, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{idea.name}</TableCell>
                      <TableCell align={"right"}>{idea.weeks}</TableCell>
                      <TableCell align={"right"}>{formatCurrency(idea.cost)}</TableCell>
                      <TableCell
                        className={classes.highlight}
                        align={"right"}>{formatNumber(idea.cumulativeDelay)} weeks</TableCell>
                      <TableCell
                        className={classes.highlight}
                        align={"right"}>{formatCurrency(idea.cumulativeCost)}</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Typography className={classes.p}>
          In this case, you won't see a dime of revenue from <strong>Idea D</strong> for 21 weeks, even
          though <strong>Idea D</strong> itself only takes 2 weeks to build.
        </Typography>
      </section>

      <section className={classes.section}>
        <Typography variant={"h4"} component={"h2"}>
          Finding the Optimal Order*
          <br/>
          <Typography variant={"caption"}>
            there is <em>at least</em> one optimal order - but there may be many
          </Typography>
        </Typography>

        <Typography className={classes.p}>
          For simple cases, it's very easy to calculate. You simply need to figure out which feature has the best "bang
          for the buck".
        </Typography>

        <Typography className={classes.p}>
          To do that, you divide the weekly cost (the cost of not having it) by the number of weeks it takes to build
          and sort by that number. This is called the <strong>CD3</strong> which stands for&nbsp;
          <strong>C</strong>ost of&nbsp;
          <strong>D</strong>elay&nbsp;
          <strong>D</strong>ivided by&nbsp;
          <strong>D</strong>uration.
        </Typography>

        <TableContainer className={classes.tableContainer}>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="col">Idea</TableCell>
                <TableCell align={"right"} component="th" scope="col">Duration</TableCell>
                <TableCell align={"right"} component="th" scope="col">Cost</TableCell>
                <TableCell align={"right"} className={classes.highlight} component="th" scope="col">CD3</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                bestSequence.ideas.map((idea, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{idea.name}</TableCell>
                      <TableCell align={"right"}>{idea.weeks}</TableCell>
                      <TableCell align={"right"}>{formatCurrency(idea.cost)}</TableCell>
                      <TableCell
                        className={classes.highlight}
                        align={"right"}>{formatNumber(idea.cd3)}</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Typography className={classes.p}>
          If you prioritize based on the CD3, from highest to lowest, your total cost of delay will be minimized.
        </Typography>
        <TableContainer className={classes.tableContainer}>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="col">Idea</TableCell>
                <TableCell align={"right"} component="th" scope="col">Duration</TableCell>
                <TableCell align={"right"} component="th" scope="col">Cost</TableCell>
                <TableCell className={classes.highlight} align={"right"} component="th" scope="col">
                  CD3
                </TableCell>
                <TableCell align={"right"} component="th" scope="col">
                  Delay
                </TableCell>
                <TableCell align={"right"} component="th" scope="col">
                  Cumulative Cost
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                bestSequence.ideas.map((idea, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{idea.name}</TableCell>
                      <TableCell align={"right"}>{idea.weeks}</TableCell>
                      <TableCell align={"right"}>{formatCurrency(idea.cost)}</TableCell>
                      <TableCell
                        className={classes.highlight}
                        align={"right"}>{formatNumber(idea.cd3)}</TableCell>
                      <TableCell
                        align={"right"}>{formatNumber(idea.cumulativeDelay)} weeks</TableCell>
                      <TableCell
                        align={"right"}>{formatCurrency(idea.cumulativeCost)}</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className={`${classes.highlight} ${classes.dark}`} align={"right"} colSpan={5}>Total
                  Cost</TableCell>
                <TableCell className={`${classes.highlight} ${classes.dark}`}
                           align={"right"}>{formatCurrency(bestSequence.totalCost)}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <Typography className={classes.p}>
          Whereas if you prioritize based on the CD3, from <em>lowest to highest</em>, your total cost of delay may
          be
          much worse.
        </Typography>
        <TableContainer className={classes.tableContainer}>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="col">Idea</TableCell>
                <TableCell align={"right"} component="th" scope="col">Duration</TableCell>
                <TableCell align={"right"} component="th" scope="col">Cost</TableCell>
                <TableCell className={classes.highlight} align={"right"} component="th" scope="col">
                  CD3
                </TableCell>
                <TableCell align={"right"} component="th" scope="col">
                  Delay
                </TableCell>
                <TableCell align={"right"} component="th" scope="col">
                  Cumulative Cost
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                worstSequence.ideas.map((idea, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{idea.name}</TableCell>
                      <TableCell align={"right"}>{idea.weeks}</TableCell>
                      <TableCell align={"right"}>{formatCurrency(idea.cost)}</TableCell>
                      <TableCell
                        className={classes.highlight}
                        align={"right"}>{formatNumber(idea.cd3)}</TableCell>
                      <TableCell
                        align={"right"}>{formatNumber(idea.cumulativeDelay)} weeks</TableCell>
                      <TableCell
                        align={"right"}>{formatCurrency(idea.cumulativeCost)}</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className={`${classes.highlight} ${classes.dark}`} align={"right"} colSpan={5}>Total
                  Cost</TableCell>
                <TableCell className={`${classes.highlight} ${classes.dark}`}
                           align={"right"}>{formatCurrency(worstSequence.totalCost)}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </section>
      <section className={classes.section}>
        <Box className={classes.callToAction}>
          <Button component={RouterLink} to={"/cost-of-delay"} variant={"contained"} color={"primary"} size={"large"}>
            Try it out for free!
          </Button>
        </Box>
      </section>
    </Container>
  );
}

export default Introduction;