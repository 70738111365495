import React, {useState} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {formatCurrency} from "../../formatter";
import {useDispatch, useSelector} from "react-redux";
import {selectIdeaScenarios, selectSummarizedIdeas, updateIdea} from "./costOfDelaySlice";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import grey from "@material-ui/core/colors/grey";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Form from "./ideas/Form";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  titleLink: {
    color: "white",
    textDecoration: "none"
  },
  navLink: {
    ...theme.typography.button,
    color: "white",
    textDecoration: "none",
    marginRight: "1em"
  },
  bar: {
    marginBottom: "2em",
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginBottom: '3em',
  },
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  firstColumn: {
    width: "1em",
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  waiting: {
    background: grey[200],
  },
  building: {
    background: amber[50],
  },
  delivered: {
    background: green[100],
  },
  waste: {
    background: red[100],
  },
  selected: {
    background: amber[50]
  },
  dropdown: {
    paddingBottom: 0,
    paddingTop: 0,
  },
});

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function Row({summarizedIdea, scenario}) {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const [value] = useState(0);
  const dispatch = useDispatch();

  return (
    <>
      <TableRow className={`${classes.root} ${open ? classes.selected : ''}`}>
        <TableCell className={classes.firstColumn}>
          <IconButton aria-label="expand row" size="small" onClick={() => {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({'event': 'ideaChanged', 'ideaAction': 'toggle'});
            setOpen(!open);
          }}>
            {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{summarizedIdea.name}</TableCell>
        <TableCell align="right">{summarizedIdea.weeks}</TableCell>
        <TableCell align="right">{formatCurrency(summarizedIdea.cost)}</TableCell>
        <TableCell align="right">{summarizedIdea.cd3.toFixed(1)}</TableCell>
        <TableCell align="right">{summarizedIdea.cumulativeDelay}</TableCell>
        <TableCell align="right">{formatCurrency(summarizedIdea.cumulativeCost)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.dropdown} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit component={"div"}>
            <Box margin={1} padding={2}>
              <TabPanel value={value} index={0}>
                <Form idea={summarizedIdea} onSubmit={idea => {
                  const dataLayer = window.dataLayer || [];
                  dataLayer.push({'event': 'ideaChanged', 'ideaAction': 'update'});
                  return dispatch(updateIdea(idea));
                }}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right"/>
                        {
                          scenario[0].map((week, i) => (
                            <TableCell align="right" key={`week-${i}`}>{i}</TableCell>
                          ))
                        }
                        <TableCell align="right">Total Cost</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        scenario.map((row, i) => (
                          <TableRow key={`row-${i}`}>
                            <TableCell align="left">{i + 1}</TableCell>
                            {
                              row.map((cell, j) => (
                                <TableCell
                                  className={{
                                    [classes[cell.state]]: true,
                                    [classes.waste]: cell.state === "delivered" && cell.waste
                                  }}
                                  align="right"
                                  key={`row-${i}-cell-${j}`}>
                                  {cell.value === 0 ? '' : (cell.type === "currency" ? formatCurrency(cell.value) : cell.value)}
                                </TableCell>
                              ))
                            }
                            <TableCell align="right">{formatCurrency(row.reduce((a, b) => a + b.value, 0))}</TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function IdeasTable() {
  const classes = useStyles();
  const {summarizedIdeas, totalCost} = useSelector(selectSummarizedIdeas);
  const scenarios = useSelector(selectIdeaScenarios);

  if (summarizedIdeas.length === 0) {
    return (<></>);
  }

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell/>
              <TableCell>Idea</TableCell>
              <TableCell align="right">Duration</TableCell>
              <TableCell align="right">Cost</TableCell>
              <TableCell align="right">CD3</TableCell>
              <TableCell align="right">Delay</TableCell>
              <TableCell align="right">Cost of Delay</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {summarizedIdeas.map((summarizedIdea) => (
              <Row key={`row-${summarizedIdea.id}`} summarizedIdea={summarizedIdea}
                   scenario={scenarios[summarizedIdea.id]}/>))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6} align={"right"} component="th" scope="row">Total Cost of Delay</TableCell>
              <TableCell align="right">{formatCurrency(totalCost)}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

export default IdeasTable;
