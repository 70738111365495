export const getCurrencyFormatter = (minimumFractionDigits, maximumFractionDigits) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits,
  maximumFractionDigits
});

export const getNumberFormatter = (minimumFractionDigits, maximumFractionDigits) => new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits,
  maximumFractionDigits
});

export const getPercentFormatter = (minimumFractionDigits, maximumFractionDigits) => new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits,
  maximumFractionDigits
});

const currencyFormatter = getCurrencyFormatter(0, 0);

export const formatCurrency = num => currencyFormatter.format(num);

const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export const formatNumber = num => numberFormatter.format(num);
