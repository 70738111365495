import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {dateField, moneyPerWeekField, weeksField} from "./fieldProps";
import Button from "@material-ui/core/Button";
import {v4 as uuidv4} from 'uuid';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {IconButton} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import {useDispatch} from "react-redux";
import {removeIdea} from "../costOfDelaySlice";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

const useStyles = makeStyles(() => ({
  root: {
    background: "white"
  },
  delete: {
    float: "right",
  }
}));

function Form({onSubmit, idea}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [id] = useState(idea ? idea.id : uuidv4());
  const [name, setName] = useState(idea ? idea.name : '');
  const [cost, setCost] = useState(idea ? idea.cost : undefined);
  const [weeks, setWeeks] = useState(idea ? idea.weeks : undefined);
  const [externality, setExternality] = useState(idea ? idea.externality : undefined);

  let initialRadioValue;
  if (externality && (externality.hasOwnProperty("contractStartDate") || externality.hasOwnProperty("contractTermWeeks"))) {
    initialRadioValue = "0";
  } else if (externality && externality.hasOwnProperty("oneTimeCostDate")) {
    initialRadioValue = "1";
  } else if (externality && externality.hasOwnProperty("recurringCostStartDate")) {
    initialRadioValue = "2";
  } else if (externality && externality.hasOwnProperty("perpetualLossStartDate")) {
    initialRadioValue = "3";
  }

  const [radioValue, setRadioValue] = useState(initialRadioValue);

  const changeRadioValue = (e) => {
    setRadioValue(e.target.value);
  };

  const valid = name.length > 0
    && typeof cost !== "undefined"
    && cost > 0
    && typeof weeks !== "undefined"
    && weeks > 0;

  // TODO: validate externality

  return (
    <form className={classes.root} onSubmit={e => {
      e.preventDefault();
      onSubmit({
        id,
        name,
        cost,
        weeks,
        externality,
      });
    }}>
      <Box p={2} mb={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box mb={1}>
              <Typography variant={"h6"}>
                {idea ? "Update Idea" : "Add a Product Idea"}
              </Typography>
              <Typography>
                Add a short name for this idea:
              </Typography>
            </Box>
            <TextField id="name"
                       fullWidth
                       autoFocus
                       autoComplete={"off"}
                       variant={"filled"}
                       label="Name"
                       value={name}
                       onChange={e => setName(e.target.value)}/>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mb={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box mb={1}>
              <Typography>
                How much does it cost you to <em>not</em> have this idea live right now?
              </Typography>
            </Box>
            <TextField {...moneyPerWeekField}
                       id="cost"
                       fullWidth={false}
                       label="Cost"
                       inputProps={{min: 1}}
                       value={typeof cost === "undefined" ? '' : cost}
                       onChange={e => setCost(e.target.value ? +e.target.value : undefined)}/>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mb={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box mb={1}>
              <Typography variant={"body1"}>
                How many weeks to you expect it will take for you to build and ship this?
              </Typography>
            </Box>
            <TextField {...weeksField}
                       id="weeks"
                       label="Weeks to Build"
                       fullWidth={false}
                       inputProps={{min: 1}}
                       value={typeof weeks === "undefined" ? '' : weeks}
                       onChange={e => setWeeks(e.target.value ? +e.target.value : undefined)}/>
          </Grid>
        </Grid>
      </Box>

      {
        externality && (
          <Box p={2} mb={2}>
            <Button onClick={e => setExternality(null)}>Remove Externality</Button>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box p={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Type of Externality</FormLabel>
                    <RadioGroup aria-label="gender" name="gender1" value={radioValue} onChange={changeRadioValue}>
                      <FormControlLabel value="0" control={<Radio/>} label="Contract Renewal"/>
                      <FormControlLabel value="1" control={<Radio/>} label="One-Time Fine (or loss)"/>
                      <FormControlLabel value="2" control={<Radio/>} label="Recurring Loss"/>
                      <FormControlLabel value="3" control={<Radio/>} label="Perpetual Loss"/>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box mb={1}>
                  {
                    radioValue === "0" && (
                      <Typography variant={"body1"} component={"div"}>
                        Contract (for example a vendor contract that renews every month or year)
                        <Box mt={2}>
                          <TextField {...dateField}
                                     id="externality-contract-start-date"
                                     label="Next Contract Renewal Date"
                                     fullWidth
                                     value={typeof externality.contractStartDate === "undefined" ? '' : externality.contractStartDate}
                                     onChange={e => {
                                       setExternality(current => ({
                                         ...current,
                                         contractStartDate: e.target.value
                                       }));
                                     }}/>
                        </Box>
                        <Box mt={2}>
                          <TextField {...weeksField}
                                     id="externality-contract-start-date"
                                     label="Contract Term"
                                     fullWidth
                                     value={typeof externality.contractTermWeeks === "undefined" ? '' : externality.contractTermWeeks}
                                     onChange={e => {
                                       setExternality(current => ({
                                         ...current,
                                         contractTermWeeks: e.target.value
                                       }));
                                     }}/>
                        </Box>
                      </Typography>
                    )
                  }

                  {
                    radioValue === "1" && (
                      <Typography variant={"body1"} component={"div"}>
                        One-Time Cost (for example a fine)
                        <Box mt={2}>
                          <TextField {...dateField}
                                     id="externality-one-time-cost"
                                     label="Date"
                                     fullWidth
                                     value={typeof externality.oneTimeCostDate === "undefined" ? '' : externality.oneTimeCostDate}
                                     onChange={e => setExternality({oneTimeCostDate: e.target.value})}/>
                        </Box>
                      </Typography>
                    )
                  }

                  {
                    radioValue === "2" && (
                      <Typography variant={"body1"} component={"div"}>
                        Recurring Loss
                        <Box mt={2}>
                          <TextField {...dateField}
                                     id="externality-recurring-cost"
                                     label="Date"
                                     fullWidth
                                     value={typeof externality.recurringCostStartDate === "undefined" ? '' : externality.recurringCostStartDate}
                                     onChange={e => setExternality({recurringCostStartDate: e.target.value})}/>
                        </Box>
                      </Typography>
                    )
                  }

                  {
                    radioValue === "3" && (
                      <Typography variant={"body1"} component={"div"}>
                        Perpetual Loss (for example losing market share)
                        <Box mt={2}>
                          <TextField {...dateField}
                                     id="externality-perpetual-loss"
                                     label="Date"
                                     fullWidth
                                     value={typeof externality.perpetualLossStartDate === "undefined" ? '' : externality.perpetualLossStartDate}
                                     onChange={e => setExternality({perpetualLossStartDate: e.target.value})}/>
                        </Box>
                      </Typography>
                    )
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
        )
      }

      {
        !externality && localStorage.getItem("costOfDelayExternalitiesEnabled") === "true" && (
          <Box p={2} mb={2}>
            <Button onClick={e => setExternality({})}>Add Externality</Button>
          </Box>
        )
      }

      <Box p={2} mb={2}>
        <div className={classes.saveBar}>
          <Button type={"submit"} disabled={!valid} variant={"contained"} color={"primary"} size={"large"}>
            {idea ? "Update" : "Add"}
          </Button>
          {
            idea && (
              <IconButton className={classes.delete} variant={"contained"} onClick={e => {
                dispatch(removeIdea(idea));
                const dataLayer = window.dataLayer || [];
                dataLayer.push({'event': 'ideaChanged', 'ideaAction': 'remove'});
              }}>
                <Delete/>
              </IconButton>
            )
          }
        </div>
      </Box>
    </form>
  );
}

export default Form;
