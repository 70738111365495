import React from "react";
import {Container} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Title from "../../shared/Title";
import Button from "@material-ui/core/Button";
import {Link as RouterLink} from "react-router-dom";
import teal from "@material-ui/core/colors/teal";

const useStyles = makeStyles(() => ({
  section: {
    marginTop: "2em",
    paddingBottom: "5em",
  },
  p: {
    marginBottom: "1em"
  },
  callToAction: {
    textAlign: "center",
    background: teal[100],
    padding: "2em"
  },
}));

function WorkingAcrossHorizons() {
  const classes = useStyles();

  return (
    <Container maxWidth={"md"}>
      <Title text={"Is your org structure wrong?"}/>
      <Typography variant={"h3"} component={"h1"}>
        Is your org structure wrong?
      </Typography>
      <section className={classes.section}>
        <Typography className={classes.p}>
          If you are here, reading about Cost of Delay models, it probably means that you have more work than your team
          can do. Sometimes that’s just life, and it’s totally normal and healthy. But in other cases, it could mean
          that your team is being asked to do the impossible: continue to improve the efficiency of current business
          models while simultaneously working to disrupt those business models.
        </Typography>
        <Box className={classes.callToAction}>
          <Typography variant={"subtitle1"}>
            Don't know what a Cost of Delay model is?
          </Typography>
          <Button component={RouterLink} to={"/cost-of-delay/introduction"} variant={"contained"} color={"primary"}
                  size={"large"}>
            Learn More
          </Button>
        </Box>
      </section>
      <section className={classes.section}>
        <Typography variant={"h4"} component={"h2"}>
          Imagine this scenario:
        </Typography>
        <Typography className={classes.p}>
          There’s a team called The Redwoods. They maintain a large Java codebase that’s of drinking age (21 years old).
          The website brings in $14MM in revenue per day and is the front face of the brand. But consumer behaviors are
          shifting, and the website revenue is steadily declining. The company is losing market share to competitors who
          have stronger mobile offerings.
        </Typography>
        <Typography className={classes.p}>
          The Redwoods have a lot on their plate. Website performance is slower than customers want, so they want to
          improve that. And they also would like to replatform the application to run on Kubernetes to save on operating
          costs and improve software delivery performance.
        </Typography>
        <Typography className={classes.p}>
          Then, a senior leader tells the team that the company’s #1 priority is to build out a better “mobile presence”
          to regain market share.
        </Typography>
        <Typography className={classes.p}>
          Now imagine you are the product manager for the Redwoods. How would you plot these requests on a Cost of Delay
          model?
        </Typography>
        <Typography className={classes.p}>
          On one hand, you have a huge and vague idea: build a “mobile presence” to regain lost revenue. What would you
          put in the “Weeks to Build” column? 104? And for value, what do you expect this “mobile presence” would net?
          Finally, how could you possibly compare these two?
        </Typography>
        <Typography className={classes.p}>
          The answer is, “you can’t.” These two efforts should not only be managed by different teams, they should be
          managed in entirely different parts of the company.
        </Typography>
        <Typography className={classes.p}>
          McKinsey offers a great way to think about ideas as being <a
          href={"https://www.mckinsey.com/business-functions/strategy-and-corporate-finance/our-insights/enduring-ideas-the-three-horizons-of-growth"}
          target={"_blank"} rel={"noopener noreferrer"}>organized into horizons</a>:
        </Typography>
        <Typography className={classes.p} component={"ol"}>
          <li>Horizon 1: The core business. Generates top-line revenue to the company</li>
          <li>Horizon 2: New business models, ready to start scaling, but not mature yet</li>
          <li>Horizon 3: Venture-capital-esque ideas. Most will fail. Some could hit it big several years later</li>
        </Typography>
        <Typography className={classes.p}>
          There’s a growing consensus that companies should protect and insulate entrepreneurial ventures from the rest
          of the company. These centers for innovation should be managed by different people. They should be staffed by
          different personality types. And they should be funded separately from mainline, operations business models.
        </Typography>
        <Typography className={classes.p}>
          Eric Ries famously introduced this concept in The Lean Startup when he described how <a
          href={"http://www.intuitlabs.com/"} target={"_blank"} rel={"noopener noreferrer"}>Intuit</a>&nbsp;
          carved out space for internal entrepreneurial ventures. You can also read about
          it in <a href={"https://www.zonetowin.com/"} target={"_blank"} rel={"noopener noreferrer"}>Zone to Win</a>, by Geoffrey Moore, as well
          as <a href={"https://www.strategyzer.com/books/the-invincible-company"} target={"_blank"} rel={"noopener noreferrer"}>The Invincible
          Company</a>. And, at the risk of spoiling the ending, you’ll
          also see this idea crop up in <a href={"https://itrevolution.com/the-unicorn-project/"} target={"_blank"} rel={"noopener noreferrer"}>The
          Unicorn Project</a>.
        </Typography>
        <Typography className={classes.p}>
          Cost of Delay models are great for existing, already-scaled lines of business with low volatility. But they
          are useless for early-stage ideas. So if you are having trouble building a model, ask yourself whether the
          ideas in the list are actually coming from different Horizons.
        </Typography>
      </section>
    </Container>
  );
}

export default WorkingAcrossHorizons;