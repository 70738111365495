import React, {useEffect, useState} from 'react';
import ScrollToTop from "../../shared/ScrollToTop";
import {Link as RouterLink, useHistory, useParams} from "react-router-dom";
import IdeasTable from "./IdeasTable";
import {useDispatch, useSelector} from "react-redux";
import {addIdea, replaceData, selectIdeaCount} from "./costOfDelaySlice";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import StyledBreadcrumbs from "../../shared/StyledBreadcrumbs";
import Title from "../../shared/Title";
import Link from "@material-ui/core/Link";
import {Add, Close, SaveOutlined} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";
import {green} from "@material-ui/core/colors";
import Form from "./ideas/Form";
import Fab from "@material-ui/core/Fab";
import Spreadsheet from "./Spreadsheet";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import {db} from "../../services/firebase";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import emptyIdeas from "../../images/empty-ideas.svg";

const useStyles = makeStyles(theme => ({
  avatar: {
    float: "right",
    backgroundColor: green[500],
  },
  close: {
    float: "right",
    backgroundColor: green[200],
  },
  saveButton: {
    margin: "0 1em"
  },
  empty: {
    textAlign: "right",
  },
  emptyImg: {
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
  }
}));

function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const ideaCount = useSelector(selectIdeaCount);
  const state = useSelector(state => state);
  const params = useParams();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };
  useEffect(() => {
    (async () => {
      if (params.modelId) {
        const snapshot = await db.collection("freemium-models").doc(params.modelId).get();
        const data = snapshot.data();
        dispatch(replaceData(data));
      }
    })();
  }, [dispatch, params]);

  return (
    <Container>
      <Box mb={6}>
        <Title text={"Playground"}/>
        <ScrollToTop/>
        <Grid container>
          <Grid item xs={6}>
            <StyledBreadcrumbs aria-label="breadcrumb">
              <Link component={RouterLink} color="inherit" to="/">
                Home
              </Link>
              <Typography color="textPrimary">Cost of Delay</Typography>
            </StyledBreadcrumbs>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={6}>
            <Box mb={2}>
              <Typography variant="h6">
                Product Ideas &nbsp;
                {
                  ideaCount > 0 && (
                    <small>
                      (listed in priority order from highest to lowest)
                    </small>
                  )
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justify={"flex-end"}>
              <Grid item>
                {
                  ideaCount > 0 && (
                    <>
                      <Button
                        variant={"contained"}
                        className={classes.saveButton}
                        onClick={async e => {
                          const doc = await db.collection("freemium-models").add(state);
                          const dataLayer = window.dataLayer || [];
                          dataLayer.push({'event': 'ideaChanged', 'ideaAction': 'save'});
                          history.push(`/cost-of-delay/${doc.id}`);
                          setSnackbarOpen(true);
                        }}>
                        <SaveOutlined/>
                        &nbsp;
                        Save
                      </Button>
                      <Snackbar
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message="Your model was saved"
                        action={
                          <>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                              <Close fontSize="small"/>
                            </IconButton>
                          </>
                        }
                      />
                    </>
                  )
                }
                {
                  formOpen && (
                    <Fab size={"small"} color="primary" aria-label="add" className={classes.close}
                         onClick={e => setFormOpen(false)}>
                      &mdash;
                    </Fab>
                  )
                }
                {
                  !formOpen && (
                    <Fab size={"small"} color="primary" aria-label="add" className={classes.avatar}
                         onClick={e => setFormOpen(true)}>
                      <Add/>
                    </Fab>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {
          formOpen && (
            <Paper>
              <Form onSubmit={idea => {
                dispatch(addIdea(idea));
                const dataLayer = window.dataLayer || [];
                dataLayer.push({'event': 'ideaChanged', 'ideaAction': 'add'});
                setFormOpen(false);
              }}/>
            </Paper>
          )
        }

        {
          ideaCount === 0 && !formOpen && (
            <div className={classes.empty}>
              <img className={classes.emptyImg} src={emptyIdeas} alt={"Click the plus button to add an idea"}/>
            </div>
          )
        }

        <IdeasTable/>
        <Spreadsheet/>
      </Box>
    </Container>
  )
    ;
}

export default Dashboard;
